<template>
  <div>
    <Card>
      <div slot="title">
        <div class="edit-head">
          <a @click="close" class="back-title">
            <Icon type="ios-arrow-back" />返回
          </a>
          <div v-if="addEditFlag=='1'" class="head-name">添加</div>
          <div v-else class="head-name">编辑</div>
          <span></span>
          <a @click="close" class="window-close">
            <Icon type="ios-close" size="31" class="ivu-icon-ios-close" />
          </a>
        </div>
      </div>
      <Form ref="form" :model="form" :rules="formValidate" label-position="top">
        <FormItem label="案件ID" prop="ajid" hidden="true">
          <Input v-model="form.ajid" clearable />
        </FormItem>
        <FormItem label="案件类型" prop="ajlx">
          <RadioGroup type="button" v-model="form.ajlx" v-if="addEditFlag=='1'">
            <Radio v-for="(item, i) in this.$store.state.dict.ajlx" :key="i" :label="item.value">{{ item.title }}
            </Radio>
          </RadioGroup>
          <RadioGroup type="button" v-model="form.ajlx" v-if="addEditFlag=='2'">
            <Radio disabled v-for="(item, i) in this.$store.state.dict.ajlx" :key="i" :label="item.value">
              {{ item.title }}</Radio>
          </RadioGroup>
        </FormItem>

        <Collapse v-model="collapse_wtfxx" accordion v-if="form.ajlx == 0 || form.ajlx == 1">
          <Panel name="1">基本信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="委托人姓名" prop="wtrxm">
                  <Input v-model="form.wtrxm" :maxlength=15 prefix="ios-contact" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="委托人电话" prop="wtrdh">
                  <Input type="telno" v-model="form.wtrdh" :maxlength=12 prefix="ios-call-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="委托人联系方式" prop="wtrlxfs">
                  <Input v-model="form.wtrlxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="案由" prop="ay">
                  <Input v-model="form.ay" :maxlength=15 prefix="ios-create-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="委托日期" prop="wtrq">
                  <DatePicker type="date" v-model="form.wtrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="案件结项标志" prop="ajzt">
                  <Select prefix="ios-cube-outline" v-model="form.ajzt">
                    <Option v-for="(item, i) in this.$store.state.dict.ajzt" :key="i" :value="item.value">
                      {{ item.title }}</Option>
                  </Select>
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_dfxx" accordion v-if="form.ajlx == 0">
          <Panel name="1">对方基本信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="对方当事人" prop="dfdsr">
                  <Input v-model="form.dfdsr" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="16">
                <FormItem label="对方联系方式" prop="dflxfs">
                  <Input v-model="form.dflxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="对方代理人姓名" prop="dflsxm">
                  <Input v-model="form.dflsxm" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="16">
                <FormItem label="代理人联系方式" prop="dflslxfs">
                  <Input v-model="form.dflslxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_xyrxx" accordion v-if="form.ajlx == 1">
          <Panel name="1">嫌疑人公安检察院信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="嫌疑人" prop="xyr">
                  <Input v-model="form.xyr" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="涉嫌罪名" prop="sxzm">
                  <Input v-model="form.sxzm" :maxlength=20 prefix="ios-create-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="拘留日期" prop="jlrq">
                  <DatePicker type="date" v-model="form.jlrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="逮捕日期" prop="dbrq">
                  <DatePicker type="date" v-model="form.dbrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="经办民警" prop="jbmj">
                  <Input v-model="form.jbmj" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="经办民警联系方式" prop="jbmjlxfs">
                  <Input v-model="form.jbmjlxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="经办检察官" prop="jbjcg">
                  <Input v-model="form.jbjcg" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="经办检察官联系方式" prop="jcglxfs">
                  <Input v-model="form.jcglxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_sfxx" accordion v-if="form.ajlx == 0 || form.ajlx == 1">
          <Panel name="1">收费信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="律师费" prop="lsf">
                  <Input v-model="form.lsf" :maxlength=15 prefix="logo-usd" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="合办律师" prop="hhls">
                  <Input v-model="form.hhls" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="案件介绍人" prop="ajjsr">
                  <Input v-model="form.ajjsr" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="律所案号" prop="lsah">
                  <Input v-model="form.lsah" :maxlength=20 prefix="ios-create-outline" clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_fyxx" accordion v-if="form.ajlx == 0 || form.ajlx == 1">
          <Panel name="1">法院及判决信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="收案法院" prop="fym">
                  <Input v-model="form.fym" :maxlength=15 prefix="ios-train-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="立案日期" prop="larq">
                  <DatePicker type="date" v-model="form.larq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32" accordion v-show="form.ajlx == 0">
                <Col span="8">
                <FormItem label="诉前保全日期" prop="sqbqrq">
                  <DatePicker type="date" v-model="form.sqbqrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="诉前保全期限（天数）" prop="sqbqqx">
                  <Input v-model="form.sqbqqx" :maxlength=3 prefix="ios-timer-outline" />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="是否开启提醒" prop="sqbqtx">
                  <i-switch size="large" v-model="form.sqbqtx" :true-value="'1'" :false-value="'0'">
                    <span style="width: 100px" slot="open">开启</span>
                    <span style="width: 100px" slot="close">关闭</span>
                  </i-switch>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32" v-show="form.ajlx == 0">
                <Col span="8">
                <FormItem label="举证日期" prop="jzrq">
                  <DatePicker type="date" v-model="form.jzrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="举证期限（天数）" prop="jzqx">
                  <Input v-model="form.jzqx" :maxlength=3 prefix="ios-timer-outline" />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="是否开启提醒" prop="jztx">
                  <i-switch size="large" v-model="form.jztx" :true-value="'1'" :false-value="'0'">
                    <span style="width: 100px" slot="open">开启</span>
                    <span style="width: 100px" slot="close">关闭</span>
                  </i-switch>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32" v-show="form.ajlx == 0">
                <Col span="8">
                <FormItem label="诉中保全日期" prop="szbqrq">
                  <DatePicker type="date" v-model="form.szbqrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="诉中保全期限（天数）" prop="szbqqx">
                  <Input v-model="form.szbqqx" :maxlength=3 prefix="ios-timer-outline" />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="是否开启提醒" prop="szbqtx">
                  <i-switch size="large" v-model="form.szbqtx" :true-value="'1'" :false-value="'0'">
                    <span style="width: 100px" slot="open">开启</span>
                    <span style="width: 100px" slot="close">关闭</span>
                  </i-switch>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="开庭日期" prop="ktrq">
                  <DatePicker type="date" v-model="form.ktrq" style="display: block" format="yyyy-MM-dd" clearable>
                  </DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="开庭时间" prop="ktsj">
                  <TimePicker type="time" format="HH:mm" v-model="form.ktsj" style="display: block" clearable>
                  </TimePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="是否开启提醒" prop="ktrqtx">
                  <i-switch size="large" v-model="form.ktrqtx" :true-value="'1'" :false-value="'0'">
                    <span style="width: 100px" slot="open">开启</span>
                    <span style="width: 100px" slot="close">关闭</span>
                  </i-switch>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32" v-show="form.ajlx == 0">
                <Col span="8">
                <FormItem label="诉讼费" prop="ssf">
                  <Input v-model="form.ssf" :maxlength=15 prefix="logo-usd" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="保全费" prop="bqf">
                  <Input v-model="form.bqf" :maxlength=15 prefix="logo-usd" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="法院案号" prop="fyah">
                  <Input v-model="form.fyah" :maxlength=20 prefix="ios-create-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="承办法官" prop="fg">
                  <Input v-model="form.fg" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="法官联系方式" prop="fglxfs">
                  <Input v-model="form.fglxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="书记员" prop="sjy">
                  <Input v-model="form.sjy" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="16">
                <FormItem label="书记员联系方式" prop="sjylxfs">
                  <Input v-model="form.sjylxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="24">
                <FormItem label="判决简要" prop="pjjy">
                  <Input type="textarea" v-model="form.pjjy" :maxlength=200 :rows=2 show-word-limit clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="24">
                <FormItem label="备注" prop="bz">
                  <Input type="textarea" v-model="form.bz" :maxlength=200 :rows=2 show-word-limit clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>
        <Collapse v-model="collapse_dsrxx" accordion v-if="form.ajlx == 0">
          <Panel name="1">第三人信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="第三人人姓名" prop="dsrxm">
                  <Input v-model="form.dsrxm" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="16">
                <FormItem label="第三人联系方式" prop="dsrlxfs">
                  <Input v-model="form.dsrlxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_gwdwxx" accordion v-if="form.ajlx == 2">
          <Panel name="1">顾问单位信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="顾问单位" prop="gwdw">
                  <Input v-model="form.gwdw" :maxlength=15 prefix="ios-train-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="顾问单位联系人" prop="gwdwlxr">
                  <Input v-model="form.gwdwlxr" :maxlength=15 prefix="ios-contact-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="顾问单位联系电话" prop="gwdwlxrdh">
                  <Input v-model="form.gwdwlxrdh" :maxlength=12 prefix="ios-call-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="顾问单位联系方式" prop="gwdwlxrlxfs">
                  <Input v-model="form.gwdwlxrlxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="顾问开始日期" prop="gwksrq">
                  <DatePicker type="date" v-model="form.gwksrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="顾问结束日期" prop="gwjsrq">
                  <DatePicker type="date" v-model="form.gwjsrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="顾问费" prop="gwf">
                  <Input v-model="form.gwf" :maxlength=15 prefix="logo-usd" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="案件结项标志" prop="ajzt">
                  <Select prefix="ios-cube-outline" v-model="form.ajzt">
                    <Option v-for="(item, i) in this.$store.state.dict.ajzt" :key="i" :value="item.value">
                      {{ item.title }}</Option>
                  </Select>
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <Collapse v-model="collapse_fsajxx" accordion v-if="form.ajlx == 3">
          <Panel name="1">委托方信息
            <p slot="content">
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="委托人姓名" prop="wtrxm">
                  <Input v-model="form.wtrxm" :maxlength=15 prefix="ios-contact" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="委托人电话" prop="wtrdh">
                  <Input v-model="form.wtrdh" :maxlength=12 prefix="ios-call-outline" clearable />
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="委托人联系方式" prop="wtrlxfs">
                  <Input v-model="form.wtrlxfs" :maxlength=50 prefix="ios-paper-outline" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="收案日期" prop="gwksrq">
                  <DatePicker type="date" v-model="form.gwksrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="完成日期" prop="gwjsrq">
                  <DatePicker type="date" v-model="form.gwjsrq" style="display: block" clearable></DatePicker>
                </FormItem>
                </Col>
                <Col span="8">
                <FormItem label="律师费" prop="lsf">
                  <Input v-model="form.lsf" :maxlength=12 prefix="logo-usd" clearable />
                </FormItem>
                </Col>
              </Row>
              <Row :gutter="32">
                <Col span="8">
                <FormItem label="案件结项标志" prop="ajzt">
                  <Select prefix="ios-cube-outline" v-model="form.ajzt">
                    <Option v-for="(item, i) in this.$store.state.dict.ajzt" :key="i" :value="item.value">
                      {{ item.title }}</Option>
                  </Select>
                </FormItem>
                </Col>
                <Col span="16">
                <FormItem label="备注" prop="bz">
                  <Input type="textarea" v-model="form.bz" show-word-limit :maxlength=200 :rows=2 clearable />
                </FormItem>
                </Col>
              </Row>
            </p>
          </Panel>
        </Collapse>

        <br />
        <Form-item class="br">
          <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
          <Button @click="close">关闭</Button>
        </Form-item>
      </Form>
    </Card>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    addCaseMain,
    editCaseMain,
  } from "@/api/busi/case-manage/case-main";
  import {
    validateMobile2,
    validateQx,
    validateNumber
  } from "@/libs/validate";
  export default {
    name: "addEdit",
    components: {},
    props: {
      data: Object,
      addEditFlag: String
    },
    data() {
      return {
        submitLoading: false, // 表单提交状态
        form: {
          // 添加或编辑表单对象初始化数据
          telno: "",
          ajlx: "0",
          ajid: -1,
          wtrxm: "",
          wtrdh: "",
          wtrq: "",
          wtrlxfs: "",
          ay: "",
          dfdsr: "",
          dflxfs: "",
          dflsxm: "",
          dflslxfs: "",
          lsf: 0,
          hhls: "",
          ajjsr: "",
          lsah: "",
          fym: "",
          sqbqrq: "",
          sqbqqx: 0,
          sqbqtx: "0",
          larq: "",
          jzrq: "",
          jzqx: 0,
          jztx: 0,
          szbqrq: "",
          szbqqx: 0,
          szbqtx: "0",
          fyah: "",
          ssf: 0,
          bqf: 0,
          ktrq: "",
          ktsj: "",
          ktrqtx: 0,
          fg: "",
          fglxfs: "",
          sjy: "",
          sjylxfs: "",
          pjjy: "",
          bz: "",
          dsrxm: "",
          dsrlxfs: "",
          xyr: "",
          sxzm: "",
          jlrq: "",
          dbrq: "",
          jbmj: "",
          jbmjlxfs: "",
          yjjcyrq: "",
          jbjcg: "",
          jcglxfs: "",
          gwdw: "",
          gwdwlxr: "",
          gwdwlxrdh: "",
          gwdwlxrlxfs: "",
          gwksrq: "",
          gwjsrq: "",
          gwf: 0,
          ajzt: "",
        },
        // 表单验证规则
        formValidate: {
          wtrxm: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
          gwdw: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
          // wtrq: [{
          //   required: true,
          //   message: "不能为空",
          //   trigger: "blur"
          // }],
          // gwksrq: [{
          //   required: true,
          //   message: "不能为空",
          //   trigger: "blur"
          // }],
          wtrdh: [{
            validator: validateMobile2,
            trigger: "blur"
          }],
          sqbqqx: [{
            validator: validateNumber,
            trigger: "change"
          }, {
            validator: validateQx,
            trigger: "blur"
          }],
          jzqx: [{
            validator: validateNumber,
            trigger: "blur"
          }, {
            validator: validateQx,
            trigger: "blur"
          }],
          szbqqx: [{
            validator: validateNumber,
            trigger: "blur"
          }, {
            validator: validateQx,
            trigger: "blur"
          }],

        },
        collapse_wtfxx: "1",
        collapse_dfxx: "0",
        collapse_sfxx: "0",
        collapse_fyxx: "0",
        collapse_dsrxx: "0",
        collapse_xyrxx: "0",
        collapse_gwdwxx: "1",
        collapse_fsajxx: "1",
      };
    },
    methods: {
      init() {
        // 编辑情况下
        if (this.addEditFlag == "2") {
          this.form = this.data;
        }else{
           delete this.form.id;
        }
      },
      handleReset() {
        this.$refs.form.resetFields();
      },
      dynCheck(){
          if (this.form.ajlx == "0" || this.form.ajlx == "1") {
            if (this.form.wtrq==undefined || this.form.wtrq=="") {
              this.$Message.warning("请输入委托日期");
              return false;
            }
          }

          if (this.form.ajlx == "2") {
            if (this.form.gwksrq==undefined || this.form.gwksrq=="") {
              this.$Message.warning("请输入顾问开始日期");
              return false;
            }
          }
          
          if (this.form.ajlx == "3") {
            if (this.form.gwksrq==undefined || this.form.gwksrq=="") {
              this.$Message.warning("请输入收案日期");
              return false;
            }
          }

          if (this.form.sqbqtx == "1") {
            if (this.form.sqbqrq==undefined || this.form.sqbqrq=="") {
               this.$Message.warning("请输入诉前保全日期");
              return false;
            }
            if (this.form.sqbqqx==undefined || parseInt(this.form.sqbqqx)<=0) {
               this.$Message.warning("请输入诉前保全期限");
              return false;
            }
          }

          if (this.form.szbqtx == "1") {
            if (this.form.szbqrq==undefined || this.form.szbqrq=="") {
               this.$Message.warning("请输入诉中保全日期");
              return false;
            }
            if (this.form.szbqqx==undefined || parseInt(this.form.szbqqx)<=0) {
               this.$Message.warning("请输入诉中保全期限");
              return false;
            }
          }

          if (this.form.jztx == "1") {
            if (this.form.jzrq==undefined || this.form.jzrq=="") {
               this.$Message.warning("请输入举证日期");
              return false;
            }
            if (this.form.jzqx==undefined || parseInt(this.form.jzqx)<=0) {
               this.$Message.warning("请输入举证期限");
              return false;
            }
          }

          if (this.form.ktrqtx == "1") {
            if (this.form.ktrq==undefined || this.form.ktrq=="") {
               this.$Message.warning("请输入开庭日期");
              return false;
            }
            if (this.form.ktsj==undefined || this.form.ktsj=="") {
               this.$Message.warning("请输入开庭时间");
              return false;
            }
          }
          
          return true;
      },
      handleSubmit() {
        if (this.dynCheck()==false) {
           return;
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.checkDate();
            this.clear(this.form.ajlx);
            if (this.form.ajid == -1) {
              addCaseMain(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.submited();
                }
              });
            }else{
              editCaseMain(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.submited();
                }
              });
            }
          }else{
            this.$Message.warning("请检查界面输入信息");
          }
        });
      },
      close() {
        this.$emit("close", true);
      },
      submited() {
        this.$emit("submited", true);
      },
      clear(ajlx) {
        if (ajlx == "0") {
          this.form.xyr = "";
          this.form.sxzm = "";
          this.form.jlrq = "";
          this.form.dbrq = "";
          this.form.jbmj = "";
          this.form.jbmjlxfs = "";
          this.form.yjjcyrq = "";
          this.form.jbjcg = "";
          this.form.jcglxfs = "";

          this.form.gwdw = "";
          this.form.gwdwlxr = "";
          this.form.gwdwlxrdh = "";
          this.form.gwdwlxrlxfs = "";
          this.form.gwksrq = "";
          this.form.gwjsrq = "";
          this.form.gwf = 0;
        }

        if (ajlx == "1") {
          this.form.dfdsr = "";
          this.form.dflxfs = "";
          this.form.dflsxm = "";
          this.form.dflslxfs = "";

          this.form.sqbqrq = "";
          this.form.sqbqqx = 0;
          this.form.sqbqtx = 0;
          this.form.jzrq = "";
          this.form.jzqx = 0;
          this.form.jztx = 0;
          this.form.szbqrq = "";
          this.form.szbqqx = 0;
          this.form.szbqtx = 0;
          this.form.ssf = 0;
          this.form.bqf = 0;

          this.form.dsrxm = "";
          this.form.dsrlxfs = "";

          this.form.gwdw = "";
          this.form.gwdwlxr = "";
          this.form.gwdwlxrdh = "";
          this.form.gwdwlxrlxfs = "";
          this.form.gwksrq = "";
          this.form.gwjsrq = "";
          this.form.gwf = 0;
        }

        if (ajlx == "2") {
          this.form.wtrxm = "";
          this.form.wtrdh = "";
          this.form.wtrq = "";
          this.form.wtrlxfs = "";
          this.form.ay = "";
          this.form.dfdsr = "";
          this.form.dflxfs = "";
          this.form.dflsxm = "";
          this.form.dflslxfs = "";
          this.form.lsf = 0;
          this.form.hhls = "";
          this.form.ajjsr = "";
          this.form.lsah = "";
          this.form.fym = "";
          this.form.sqbqrq = "";
          this.form.sqbqqx = 0;
          this.form.sqbqtx = 0;
          this.form.larq = "";
          this.form.jzrq = "";
          this.form.jzqx = 0;
          this.form.jztx = 0;
          this.form.szbqrq = "";
          this.form.szbqqx = 0;
          this.form.szbqtx = 0;
          this.form.fyah = "";
          this.form.ssf = 0;
          this.form.bqf = 0;
          this.form.ktrq = "";
          this.form.ktsj = "";
          this.form.ktrqtx = 0;
          this.form.fg = "";
          this.form.fglxfs = "";
          this.form.sjy = "";
          this.form.sjylxfs = "";
          this.form.pjjy = "";
          this.form.bz = "";
          this.form.dsrxm = "";
          this.form.dsrlxfs = "";
          this.form.xyr = "";
          this.form.sxzm = "";
          this.form.jlrq = "";
          this.form.dbrq = "";
          this.form.jbmj = "";
          this.form.jbmjlxfs = "";
          this.form.yjjcyrq = "";
          this.form.jbjcg = "";
          this.form.jcglxfs = "";
        }

        if (ajlx == "3") {
          this.form.wtrq = "";
          this.form.ay = "";
          this.form.dfdsr = "";
          this.form.dflxfs = "";
          this.form.dflsxm = "";
          this.form.dflslxfs = "";
          this.form.lsf = 0;
          this.form.hhls = "";
          this.form.ajjsr = "";
          this.form.lsah = "";
          this.form.fym = "";
          this.form.sqbqrq = "";
          this.form.sqbqqx = 0;
          this.form.sqbqtx = 0;
          this.form.larq = "";
          this.form.jzrq = "";
          this.form.jzqx = 0;
          this.form.jztx = 0;
          this.form.szbqrq = "";
          this.form.szbqqx = 0;
          this.form.szbqtx = 0;
          this.form.fyah = "";
          this.form.ssf = 0;
          this.form.bqf = 0;
          this.form.ktrq = "";
          this.form.ktsj = "";
          this.form.ktrqtx = 0;
          this.form.fg = "";
          this.form.fglxfs = "";
          this.form.sjy = "";
          this.form.sjylxfs = "";
          this.form.pjjy = "";
          this.form.dsrxm = "";
          this.form.dsrlxfs = "";
          this.form.xyr = "";
          this.form.sxzm = "";
          this.form.jlrq = "";
          this.form.dbrq = "";
          this.form.jbmj = "";
          this.form.jbmjlxfs = "";
          this.form.yjjcyrq = "";
          this.form.jbjcg = "";
          this.form.jcglxfs = "";
          this.form.gwdw = "";
          this.form.gwdwlxr = "";
          this.form.gwdwlxrdh = "";
          this.form.gwdwlxrlxfs = "";
        }

      },

      checkDate() {
        if (typeof this.form.wtrq == "object") {
          this.form.wtrq = this.format(this.form.wtrq, "yyyy-MM-dd");
        }
        if (typeof this.form.sqbqrq == "object") {
          this.form.sqbqrq = this.format(this.form.sqbqrq, "yyyy-MM-dd");
        }
        if (typeof this.form.larq == "object") {
          this.form.larq = this.format(this.form.larq, "yyyy-MM-dd");
        }
        if (typeof this.form.jzrq == "object") {
          this.form.jzrq = this.format(this.form.jzrq, "yyyy-MM-dd");
        }
        if (typeof this.form.szbqrq == "object") {
          this.form.szbqrq = this.format(this.form.szbqrq, "yyyy-MM-dd");
        }
        if (typeof this.form.ktrq == "object") {
          this.form.ktrq = this.format(this.form.ktrq, "yyyy-MM-dd");
        }
        if (typeof this.form.jlrq == "object") {
          this.form.jlrq = this.format(this.form.jlrq, "yyyy-MM-dd");
        }
        if (typeof this.form.dbrq == "object") {
          this.form.dbrq = this.format(this.form.dbrq, "yyyy-MM-dd");
        }
        if (typeof this.form.yjjcyrq == "object") {
          this.form.yjjcyrq = this.format(this.form.yjjcyrq, "yyyy-MM-dd");
        }
        if (typeof this.form.gwksrq == "object") {
          this.form.gwksrq = this.format(this.form.gwksrq, "yyyy-MM-dd");
        }
        if (typeof this.form.gwjsrq == "object") {
          this.form.gwjsrq = this.format(this.form.gwjsrq, "yyyy-MM-dd");
        }

        if (typeof this.form.ktsj == "object") {
          this.form.ktsj = this.format(this.form.ktsj, "HH:mm");
        }
      },

    },
    mounted() {
      this.init();
    },
  };
</script>
<style lang="less">
  // 建议引入通用样式 具体路径自行修改 可删除下面样式代码
  // @import "../../../styles/single-common.less";
  .edit-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .back-title {
      color: #515a6e;
      display: flex;
      align-items: center;
    }

    .head-name {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      color: #17233d;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .window-close {
      z-index: 1;
      font-size: 12px;
      position: absolute;
      right: 0px;
      top: -5px;
      overflow: hidden;
      cursor: pointer;

      .ivu-icon-ios-close {
        color: #999;
        transition: color 0.2s ease;
      }
    }

    .window-close .ivu-icon-ios-close:hover {
      color: #444;
    }
  }
</style>