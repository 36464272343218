// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getCaseMainList = (params) => {
    return postRequest('/busi/caseMain/getByCondition', params)
}

// 添加
export const addCaseMain = (params) => {
    return postRequest('/busi/caseMain/insert', params)
}

// 编辑
export const editCaseMain = (params) => {
    return postRequest('/busi/caseMain/update', params)
}

// 删除
export const deleteCaseMain = (params) => {
    return postRequest('/busi/caseMain/delByIds', params)
}